import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApihelperFunction } from '../API/ApiHelperFunction';

export const initialState = {
  data: '',
};

export const getCategory = createAsyncThunk('/getHolidayList', async () => {
  const response = await ApihelperFunction({
    urlPath: '/sadmin/getcategories',
    method: 'GET',
  });

  return response;
});

const GetCategorySlice = createSlice({
  name: 'categorylistslice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getCategory.pending, (state) => {
        const newState = state;
        newState.status = 'loading';
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        const newState = state;

        newState.status = 'idle';

        newState.data = action.payload.data;
      });
  },
});

export default GetCategorySlice.reducer;
