export const initialSingleAllwance = {
  allowance: '',
  allowance_type: 'Fixed',
  Amount: '',
  Percentage: '',
  no_work_pay: 'No',
  esi_wages: 'false',
  epf_wages: 'false',
  isSubmit: 'false',
  allowanceName: '',
};

const initialState = {
  errors: {},
  isFormSubmit: false,
  loading: false,
  formData: {
    estblshId: '',
    stepOne: {
      email: '',
      image: {},
      mobile: '',
      name: '',
      landline: '',
      category: '',
      stepNo: '1',
      est_pan: null,
      stepOneErrors: {},

      address: {
        pin: '',
        city: '',
        state: '',
        address1: '',
        country: 'India',
        address2: '',
      },
      branchDetails: {
        hasBranch: 'true',
        branches: [
          {
            branchName: '',
            branchId: '',
            streetAddress: '',
            address: '',
            description: '',
          },
        ],
      },
      ownershipDeatils: [
        {
          ownerName: '',
          designation: '',
          identityNo: '',
          panDetails: '',
          mobile: '',
          email: '',
        },
      ],
      acts: [
        {
          regNo: '',
          actName: '',
        },
      ],
    },
    stepTwo: {
      esiCoverageDate: '',
      underEsicOffice: '',
      stepTwoErrors: {},
      is_esic: 0,
      esiNo: null,
      is_epf: 0,
      is_gratuityact: 0,
      is_ptax: 0,
      address: {
        pin: '',
        city: '',
        state: '',
        address1: '',
        country: 'India',

        address2: '',
      },
      epfCode: null,
      epfStatus: null,
      epfCoverageDate: '',
      underEpfOffice: '',
      epfStatusAddress: {
        pin: '',
        city: '',
        state: '',
        address1: '',
        country: 'India',

        address2: '',
      },

      pTaxStatusEc: null,
      pTaxStatusRc: null,
      pTaxSession: null,

      licCode: null,
      nicCode: null,
      gratuityActNo: null,
      gratuityCoverageDate: '',
      gratuityType: '',
    },
    stepThree: {
      salary_type: 'Monthly',
      bankDetails: [
        {
          bankName: '',
          accountNo: '',
          ifsc: '',
          branchName: '',
        },
      ],
      bonusData: {
        bonusStatus: false,
        bonusType: '',
        Exgrasia_type: false,
        incentative: false,
        otapplicable: false,
        lab_wel_fund: false,
        Exgrasia_status: false,
      },

      leaveStatus: {
        leave_status: false,
        leave_type: 'Financial Year',
        total_leave: '',
        cl: '',
        pl: '',
        ml: '',
        is_payable: false,
        weekly_close: [],
      },
      stepThreeErrors: {},
      allowanceInfo: [initialSingleAllwance],
    },
    stepFour: {
      leaveStatus: {
        leave_status: false,
        leave_type: 'Financial Year',
        total_leave: '',
        cl: '',
        pl: '',
        ml: '',
        is_payable: false,
        weekly_close: [],
      },
      holidayInfo: [],
      stepFourErrors: {},
    },
  },
};
export default initialState;
