import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  handleSnakbar: {},
};

export const snakbarSlice = createSlice({
  name: 'snakbar',
  initialState,
  reducers: {
    clearSnakbar: (state) => {
      state.handleSnakbar = {};
    },
    setSnakbar: (state, action) => {
      const { message, type } = action.payload || {};
      state.handleSnakbar.message =
        typeof message === 'string' ? message : 'something wrong ';
      state.handleSnakbar.type = type;
    },
  },
});

export const { clearSnakbar, setSnakbar } = snakbarSlice.actions;
export const snakbarState = (state) => state.snakbar;

export default snakbarSlice.reducer;
