import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApihelperFunction } from '../API/ApiHelperFunction';

export const initialState = {
  data: '',
};

export const GetDepartment = createAsyncThunk('/getDashboard', async () => {
  const response = await ApihelperFunction({
    urlPath: '/user/department_details',
    method: 'GET',
  });
  return response;
});

const GetDepertMentSlice = createSlice({
  name: 'GetDepertMentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(GetDepartment.pending, (state) => {
        const newState = state;
        newState.status = 'loading';
      })
      .addCase(GetDepartment.fulfilled, (state, action) => {
        const newState = state;
        newState.status = 'idle';

        newState.data = action.payload.data;
      });
  },
});

export default GetDepertMentSlice.reducer;
