/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { URLOPTIONS } from '../Redux/features/api/globalApiSlice';

const { hostname } = window.location;
export const commonExtendedApi = '/v1/api';

let BaseURL = URLOPTIONS.DEVELOPMENT_API + commonExtendedApi;

if (hostname.includes(URLOPTIONS.DEVELOPMENT)) {
  BaseURL = URLOPTIONS.DEVELOPMENT_API + commonExtendedApi;
}
if (hostname.includes(URLOPTIONS.PRODUCTION)) {
  BaseURL = URLOPTIONS.PRODUCTION_API + commonExtendedApi;
}

const API_URL = BaseURL;

export const ApihelperFunction = async (data) => {
  const { urlPath, method, formData, typecontent } = data;
  // debugger
  let token = '';
  try {
    token = JSON.parse(localStorage.getItem('x-access-token'));
  } catch (err) {
    /* empty */
  }

  const config = {
    data: formData,
    method: `${method}`,
    url: `${API_URL}${urlPath}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': typecontent ? 'multipart/form-data' : 'application/json',
      'x-access-token': token,
    },
  };
  let responceData = '';

  await axios(config)
    .then((response) => {
      responceData = response?.data;
    })
    .catch((error) => {
      responceData = error?.response?.data;
    });

  return responceData;
};
