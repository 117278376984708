import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const URLOPTIONS = {
  PRODUCTION_API: 'https://api-admin.creedage.com',
  PRODUCTION: 'hrms.techwens.com',
  // DEVELOPMENT_API: 'https://api-hrms-qa.techwens.com',
  DEVELOPMENT_API: 'https://api-admin.creedage.com',
  DEVELOPMENT: 'admin-dev.creedage.com',
};
export const commonExtendedApi = '/v1/api';
const { hostname } = window.location;
let BaseURL = URLOPTIONS.DEVELOPMENT_API + commonExtendedApi;

if (hostname.includes(URLOPTIONS.DEVELOPMENT)) {
  BaseURL = URLOPTIONS.DEVELOPMENT_API + commonExtendedApi;
}
if (hostname.includes(URLOPTIONS.PRODUCTION)) {
  BaseURL = URLOPTIONS.PRODUCTION_API + commonExtendedApi;
}

const API_URL = BaseURL;

const globalApiSlice = createApi({
  reducerPath: 'hrmsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      const token = JSON.parse(localStorage.getItem('x-access-token'));
      if (token) {
        headers.set('x-access-token', token);
      }

      return headers;
    },
  }),
  tagTypes: [
    'showEstablishment',
    'getBranch',
    'Designation',
    'Establishment',
    'Employee',
    'Branch',
    'Kyc',
    'HolidayList',
    'EmployeeDashboard',
    'ShiftList',
    'salaryReport',
    'epfReport',
    'esiReport',
    'payslipReport',
    'LoanReport',
    'pTaxReport',
    'department',
    'Allowance',
    'EstablishmentList',
    'SalaryList',
    'LabourWelfareFund',
    'SadminGetusers',
    // 'salaryUpdate',
    'GetCategoryList',
    'TaxSlab',
  ],
  endpoints: (builder) => ({}),
});

export default globalApiSlice;
